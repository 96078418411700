import { attach, combine, createEvent, forward, restore, sample } from 'effector'
import {
  $finalTextDropdownsPercent,
  checkFinalTextFormValidPercent,
  $finalTextFormValidPercent,
  clearFields as finalTextFormClearFieldsPercent,
  setInput as setFinalTextDropdownsPercent,
} from '@/pages/common/parts/tests/parts/final-text-form-percent/final-text-form.model'
import {
  $finalTextDropdownsPoints,
  checkFinalTextFormValidPoints,
  $finalTextFormValidPoints,
  clearFields as finalTextFormClearFieldsPoints,
  setInput as setFinalTextDropdownsPoints,
} from '@/pages/common/parts/tests/parts/final-text-form-points/final-text-points-form.model'
import {
  $isFilled as $isFilledAutomaticGenerationForm,
  clearAutomaticGenerationFields as automaticGenerationFormClearFields,
  $difficulty,
  changeDifficulty,
  $prerequisites,
  togglePrerequisites,
} from '@/pages/common/parts/tests/automatic-generation/automatic-generation-form.model'
import {
  $maxPoints,
  $taskScores,
  clearManualGenerationFields,
  setTasksBlock as setManualItems,
  toggleTaskScores,
  $tasksBlock,
} from '@/pages/common/parts/tests/manual-generation/manual-generation-form.model'
import {
  FinalTextRelated,
  LoadingFinalTextRelated,
  ManualItem,
  UpdateTestFxParams,
} from '@/features/api/test/types'
import { successToastEvent } from '@/features/toasts/toasts.model'
import { resendTestAutoItemsArrFx } from '@/features/api/test/create-test-auto-item'
import { resendTestFinalTextArrFx } from '@/features/api/test/create-test-final-text'

import { typeDropdownModule } from '@/pages/common/dropdowns/testing/type-dropdown/type-dropdown.model'
import {
  $selectedClass,
  classesDropdownModule,
  setSelectedClass,
} from '@/pages/common/dropdowns/class/classes-dropdown.model'
import {
  $selectedSubject,
  setSelectedSubject,
  subjectsDropdownModel,
} from '@/pages/common/dropdowns/subject/subjects-dropdown.model'
import { combineEvents, condition, debounce } from 'patronum'
import { navigatePush } from '@/features/navigation/navigationMethods'
import {
  resetSelectedThemes,
  $selectedThemes,
  setSelectedThemes,
  setLoadingForEditThemes,
  themesDropdownModule,
  $isPageLoaded,
  $loadingForEditThemes,
} from '@/pages/common/parts/tests/automatic-generation/parts/themes-dropdown/themes-dropdown.model'
import { resendTestManualItemsArrFx } from '@/features/api/test/create-test-manual-item'
import { getTestFx } from '@/features/api/test/get-test'
import { updateTestFx } from '@/features/api/test/update-test'
import {
  difficultyDropdownModule,
  setSelectedDifficulty,
} from '@/pages/common/dropdowns/testing/difficulty-dropdown/difficulty-dropdown.model'
import { split } from 'effector/compat'
import { DropdownItem } from '@/pages/common/types'
import { TaskBlock } from '@/pages/common/parts/tests/types'
import { LANGUAGE_DATA, TASK_TYPES_DATA } from '@/pages/bank/common/constants'
import { taskDifficultyLevel } from '@/pages/common/dropdowns/multiselectDropdown/tests-assignment-dropdown-filter/tests-assignment-dropdown.model'
import {
  resetDateTime,
  setDateTime,
} from '@/pages/common/parts/tests/parts/datetime-picker/start-to-end-datetime.model'
import { resendGroupsArrFx } from '@/features/api/test/create-test-groups'
import { GroupTestsType } from '@/pages/testing/tests/types'
import { guard } from 'effector-root'
import { getThemesTreeListFx } from '@/features/api/subject/get-themes-tree-list'
import { getValidRange } from '@/pages/testing/tests/create/test-create-page.model'

export const save = createEvent<void>()
export const clearFields = createEvent<void>()

export const resetGroupsTests = createEvent<void>()
export const setGroupsTests = createEvent<GroupTestsType[]>()
export const $groupsTests = restore(setGroupsTests, []).reset(clearFields, resetGroupsTests)

export const loadTest = createEvent<number>()
export const $testId = restore(loadTest, 0).reset(clearFields)

export const setTestLoading = createEvent<boolean>()
export const $testLoading = restore(setTestLoading, false).reset(clearFields)

export const setRedirectAfterSave = createEvent<boolean>()
const $redirectAfterSave = restore(setRedirectAfterSave, false).reset(clearFields)

export const setTestType = createEvent<string>()
export const setInitialTestType = createEvent<string>()
export const $testType = restore(setTestType, '')
  .on(setInitialTestType, (_, type) => type)
  .reset(clearFields)

export const toggleFilterByStudyYear = createEvent<boolean>()
export const setInitialFilterByStudyYear = createEvent<boolean>()
export const $filterByStudyYear = restore(toggleFilterByStudyYear, true)
  .on(setInitialFilterByStudyYear, (_, type) => type)
  .reset(clearFields)

export const saveLoadingFinalText = createEvent<{ type: string; text: LoadingFinalTextRelated[] }>()

export const setOldGroupId = createEvent<number[]>()
export const $oldGroupId = restore(setOldGroupId, []).reset(clearFields)

export const setOldAutoItemId = createEvent<number[]>()
export const $oldAutoItemId = restore(setOldAutoItemId, []).reset(clearFields)

export const setOldManualItemId = createEvent<number[]>()
export const $oldManualItemId = restore(setOldManualItemId, []).reset(clearFields)

export const setOldFinalText = createEvent<number[]>()
export const $oldFinalText = restore(setOldFinalText, []).reset(clearFields)

export const toggleArchive = createEvent<boolean>()
export const $archive = restore(toggleArchive, false).reset(clearFields)

export const setLanguage = createEvent<DropdownItem>()
export const $language = restore(setLanguage, LANGUAGE_DATA[0]).reset(clearFields)

export const setStudyYear = createEvent<string>()
export const $studyYear = restore(setStudyYear, '').reset(clearFields)

export const setSubject = createEvent<string>()
export const $subject = restore(setSubject, '').reset(clearFields)

export const setWording = createEvent<string>()
export const $wording = restore(setWording, '').reset(clearFields)

export const setContaining = createEvent<string>()
export const $containing = restore(setContaining, '').reset(clearFields)

export const loadingSetTestScope = createEvent<string>()
export const toggleTestScope = createEvent<string>()
export const $testScope = restore(toggleTestScope, '1')
  .reset(clearFields)
  .on(loadingSetTestScope, (_, testScope) => testScope)

export const setCMSComment = createEvent<string>()
export const $cmsComment = restore(setCMSComment, '').reset(clearFields)

export const toggleTestAccess = createEvent<boolean>()
export const $testAccess = restore(toggleTestAccess, true).reset(clearFields)

export const setAvailableFrom = createEvent<string | null>()
export const $availableFrom = restore(setAvailableFrom, null).reset(clearFields)

export const setAvailableTo = createEvent<string | null>()
export const $availableTo = restore(setAvailableTo, null).reset(clearFields)

export const toggleTimeLimit = createEvent<boolean>()
export const $timeLimit = restore(toggleTimeLimit, false).reset(clearFields)

export const setMaxTime = createEvent<number>()
export const resetMaxTime = createEvent<number>()
export const $maxTime = restore(setMaxTime, null).reset(clearFields, resetMaxTime)

export const $isLoadingEditTests = combine(
  getTestFx.pending,
  updateTestFx.pending,
  resendTestAutoItemsArrFx.pending,
  resendTestFinalTextArrFx.pending,
  resendTestManualItemsArrFx.pending,
  resendGroupsArrFx.pending,
  (test, UpdateTest, AutoItems, FinalTexts, ManualItems, groups) =>
    test || UpdateTest || AutoItems || FinalTexts || ManualItems || groups
)

forward({
  from: loadTest,
  to: getTestFx,
})

forward({
  from: toggleFilterByStudyYear,
  to: [
    setStudyYear.prepend(() => ''),
    setSelectedClass.prepend(() => null),
    classesDropdownModule.methods.resetDropdown,
  ],
})

const $settingsFormFields = combine(
  $archive,
  $maxTime,
  $cmsComment,
  $prerequisites,
  $taskScores,
  $filterByStudyYear,
  $availableFrom,
  $availableTo,
  $language,
  $testScope,
  (
    archive,
    maxTime,
    cmsComment,
    prerequisites,
    taskScores,
    filterByStudyYear,
    availableFrom,
    availableTo,
    language,
    testScope
  ) => {
    return {
      status: archive ? 0 : 1,
      availability: Number(testScope),
      duration_min: maxTime,
      cms_comment: cmsComment,
      use_prerequisites: prerequisites,
      use_assignment_score: taskScores,
      use_only_class_assignments: filterByStudyYear,
      available_from: availableFrom ? new Date(availableFrom) : null,
      available_to: availableTo ? new Date(availableTo) : null,
      interface_language: language.name.slice(0, 2),
    }
  }
)

const $baseForm = combine(
  $subject,
  $difficulty,
  $testType,
  $filterByStudyYear,
  $wording,
  $containing,
  $studyYear,
  $settingsFormFields,
  $testId,
  (
    selectedSubject,
    difficulty,
    testType,
    filterByStudyYear,
    wording,
    containing,
    selectedClass,
    settingsFormFields,
    id
  ): UpdateTestFxParams => ({
    id,
    name: wording,
    instruction: containing,
    generator: Number(testType),
    ...(testType === '0' && { difficulty }),
    filter_by_year: filterByStudyYear,
    study_year_id: selectedClass ? +selectedClass : null,
    subject_id: +selectedSubject!,
    ...settingsFormFields,
  })
)

const getRequiredText = (textArr: FinalTextRelated[]) => {
  return textArr.filter((item) => item.text && item.text.length > 0)
}

export const $validFinalTextPercentArr = $finalTextDropdownsPercent.map((textArr) =>
  getRequiredText(textArr)
)

export const $validRangeFinalTextPercent = $finalTextDropdownsPercent.map((textArr) =>
  getValidRange(textArr, 100)
)

export const $validFinalTextPointsArr = $finalTextDropdownsPoints.map((textArr) =>
  getRequiredText(textArr)
)

export const $validRangeFinalTextPoints = combine(
  $finalTextDropdownsPoints,
  $maxPoints,
  (fields, points) => getValidRange(fields, points)
)

export const $canSaveValidFinalText = combine(
  $testType,
  $validFinalTextPercentArr,
  $validRangeFinalTextPercent,
  $finalTextDropdownsPercent,
  $validFinalTextPointsArr,
  $validRangeFinalTextPoints,
  $finalTextDropdownsPoints,
  (
    testType,
    validFinalTextPercentArr,
    validRangeFinalTextPercent,
    finalTextPercent,
    validFinalTextPointsArr,
    validRangeFinalTextPoints,
    finalTextPoints
  ) => {
    const isValidFinalTextPercent =
      validFinalTextPercentArr.length === finalTextPercent.length && validRangeFinalTextPercent
    const isValidFinalTextPoints =
      validFinalTextPointsArr.length === finalTextPoints.length && validRangeFinalTextPoints
    return testType === '0' ? isValidFinalTextPercent : isValidFinalTextPoints
  }
)

const $canSaveGroups = combine($testScope, $groupsTests, (testScope, groups) => {
  if (testScope === '1') return true
  return testScope === '0' && groups.length > 0
  // return groups.filter((group) => !group.available_to || !group.available_from).length === 0
})

const $canSaveAutoGeneration = combine(
  $testType,
  $isFilledAutomaticGenerationForm,
  $selectedThemes,
  (testType, isFilled, selectedThemes) => {
    return (
      (testType === '0' ? isFilled : true) && (testType === '0' ? selectedThemes.length > 1 : true)
    )
  }
)

export const $canSave = combine(
  $subject,
  $wording,
  $containing,
  $studyYear,
  $canSaveValidFinalText,
  $filterByStudyYear,
  $canSaveGroups,
  $testScope,
  $canSaveAutoGeneration,
  (
    subject,
    wording,
    containing,
    studyYear,
    finalText,
    filterByStudyYear,
    canSaveGroups,
    testScope,
    canSaveAutoGeneration
  ) => {
    return (
      subject &&
      wording &&
      containing &&
      finalText &&
      (filterByStudyYear ? !!studyYear : true) &&
      canSaveGroups &&
      !!studyYear &&
      canSaveAutoGeneration
    )
  }
)

forward({
  from: getTestFx.doneData.map((res) => res.body),
  to: [
    setInitialTestType.prepend((data) => data.generator.toString()),
    typeDropdownModule.methods.itemChanged.prepend((data) =>
      data.generator === 0
        ? { name: '0', title: 'Автоматическая генерация' }
        : { name: '1', title: 'Ручная генерация' }
    ),
    setWording.prepend((data) => data.name),
    setSubject.prepend((data) => `${data.subject.id}`),
    subjectsDropdownModel.methods.itemChanged.prepend((data) => ({
      name: data.subject.id.toString(),
      title: data.subject.name,
    })),
    setSelectedSubject.prepend((data) => ({
      name: data.subject.id.toString(),
      title: data.subject.name,
    })),
    setInitialFilterByStudyYear.prepend((data) => data.use_only_class_assignments),
    classesDropdownModule.methods.itemChanged.prepend((data) => {
      if (data.study_year) {
        return {
          name: data.study_year.id.toString(),
          title: data.study_year.name,
        }
      }
      return null
    }),
    setSelectedClass.prepend((data) => {
      if (data.study_year) {
        return {
          name: data.study_year.id.toString(),
          title: data.study_year.name,
        }
      }
      return null
    }),
    setStudyYear.prepend((data) => (data.study_year ? String(data.study_year.id) : '')),
    setContaining.prepend((data) => data.instruction),
    setCMSComment.prepend((data) => data.cms_comment),
    toggleArchive.prepend((data) => !data.status),
    setLanguage.prepend((data) => {
      if (data.interface_language === 'ru') {
        return LANGUAGE_DATA[0]
      }
      if (data.interface_language === 'en') {
        return LANGUAGE_DATA[1]
      }
      return LANGUAGE_DATA[0]
    }),
    setMaxTime.prepend((data) => data.duration_min),
    toggleTimeLimit.prepend((data) => !!data.duration_min),
    changeDifficulty.prepend((data) => (data.difficulty ? data.difficulty : 0)),
    difficultyDropdownModule.methods.itemChanged.prepend((data) =>
      data.difficulty
        ? { name: '1', title: 'Тест продвинутый' }
        : { name: '0', title: 'Тест базовый' }
    ),
    setSelectedDifficulty.prepend((data) =>
      data.difficulty
        ? { name: '1', title: 'Тест продвинутый' }
        : { name: '0', title: 'Тест базовый' }
    ),
    setLoadingForEditThemes.prepend(() => true),
    saveLoadingFinalText.prepend((data) => ({
      type: String(data.generator),
      text: data.finaltext_set,
    })),
    setManualItems.prepend((data) =>
      data.manualitem_set.map((item: ManualItem) => {
        const theme = {
          name: `${item.assignments[0].theme.id || ''}`,
          title: item.assignments[0].theme.name || '',
        }
        return {
          id: item.id,
          pointsForTask: item.score,
          theme,
          tasks: item.assignments.map((assignment) => {
            return {
              name: `${assignment.id}`,
              title: `${taskDifficultyLevel(assignment.difficulty)} (${assignment.id}) ${
                TASK_TYPES_DATA.find((el) => el.name === assignment.type)!.title
              }`,
              type: assignment.type,
              score: assignment.score,
              difficulty: assignment.difficulty,
              id: assignment.id,
              has_internal_scores: assignment.has_internal_scores,
            }
          }),
          isNew: false,
        }
      })
    ),
    setOldFinalText.prepend((data) => data.finaltext_set.map((loadText) => loadText.id)),
    setOldManualItemId.prepend((data) => data.manualitem_set.map((loadManual) => loadManual.id)),
    setOldAutoItemId.prepend((data) => data.autoitem_set.map((loadAutoItem) => loadAutoItem.id)),
    setSelectedThemes.prepend((data) =>
      data.autoitem_set.map((auto_item) => ({
        name: `${auto_item.theme.id}`,
        title: auto_item.theme.name,
        id: auto_item.theme.id,
        parent_id: auto_item.id,
      }))
    ),
    themesDropdownModule.methods.setItems.prepend((data) =>
      data.autoitem_set.map((auto_item) => ({
        name: `${auto_item.theme.id}`,
        title: auto_item.theme.name,
        id: auto_item.theme.id,
        parent_id: auto_item.id,
      }))
    ),
    togglePrerequisites.prepend((data) => data.use_prerequisites),
    toggleTaskScores.prepend((data) => data.use_assignment_score),
    changeDifficulty.prepend((data) => (data.difficulty ? data.difficulty : 0)),
    setAvailableFrom.prepend((data) => data.available_from),
    setAvailableTo.prepend((data) => data.available_to),
    toggleTestAccess.prepend((data) => !!data.available_to),
    setDateTime.prepend((data) => ({
      start: data.available_from ? new Date(data.available_from) : null,
      end: data.available_to ? new Date(data.available_to) : null,
    })),
    loadingSetTestScope.prepend((data) => `${data.availability}`),
    setOldGroupId.prepend((data) => data.testgroup_set.map((group) => group.group.id)),
    setGroupsTests.prepend((data) =>
      data.testgroup_set.map((group, index) => ({
        id: index,
        name: `${group.group.id}`,
        title: group.group.name,
        available_from: group.available_from ? new Date(group.available_from) : null,
        available_to: group.available_to ? new Date(group.available_to) : null,
      }))
    ),
  ],
})

condition({
  source: saveLoadingFinalText,
  if: (payload: { type: string; text: LoadingFinalTextRelated[] }) => payload.type === '0',
  then: setFinalTextDropdownsPercent.prepend(
    (data: { type: string; text: LoadingFinalTextRelated[] }) => {
      return data.text.map((item) => ({
        id: item.id,
        score_min: item.score_min,
        score_max: item.score_max,
        text: item.text,
        score_min_item: { name: String(item.score_min), title: `${item.score_min}%` },
        score_max_item: { name: String(item.score_max), title: `${item.score_max}%` },
        isNew: false,
      }))
    }
  ),
  else: setFinalTextDropdownsPoints.prepend(
    (data: { type: string; text: LoadingFinalTextRelated[] }) => {
      return data.text.map((item) => ({
        id: item.id,
        score_min: item.score_min,
        score_max: item.score_max,
        text: item.text,
        score_min_item: { name: String(item.score_min), title: String(item.score_min) },
        score_max_item: { name: String(item.score_min), title: String(item.score_max) },
        isNew: false,
      }))
    }
  ),
})

forward({
  from: setTestType,
  to: [resetSelectedThemes, clearManualGenerationFields, automaticGenerationFormClearFields],
})

sample({
  source: $finalTextFormValidPercent,
  clock: checkFinalTextFormValidPercent,
  target: condition({
    if: (res: boolean) => res,
    then: save,
  }),
})

sample({
  source: $finalTextFormValidPoints,
  clock: checkFinalTextFormValidPoints,
  target: condition({
    if: (res: boolean) => res,
    then: save,
  }),
})

sample({
  source: $baseForm,
  clock: save,
  target: updateTestFx,
})

// Отправка auto-item или manual-item в зависимости от типа
split({
  source: updateTestFx.doneData.map((data) => data.body.id),
  match: $testType,
  cases: {
    '1': attach({
      effect: resendTestManualItemsArrFx,
      source: {
        tskBlock: $tasksBlock,
        oldId: $oldManualItemId,
      },
      mapParams: (id: number, data) => {
        const sendData = data.tskBlock.map((item: TaskBlock) => ({
          id: item.id,
          isNew: item.isNew,
          score: item.pointsForTask,
          order_number: id,
          test_id: id,
          assignments_ids: item.tasks.map((task) => Number(task.name)),
          oldId: data.oldId,
        }))
        return sendData
      },
    }),
    '0': attach({
      effect: resendTestAutoItemsArrFx,
      source: {
        themes: $selectedThemes,
        subject: $subject,
        study_year: $studyYear,
        oldId: $oldAutoItemId,
      },
      mapParams: (id: number, data) => {
        const sendData = data.themes.map((item: DropdownItem) => ({
          oldId: data.oldId,
          test_id: id,
          id: item.parent_id ? item.parent_id : 0,
          order_number: id,
          theme_id: Number(item.name),
          theme: {
            name: item.name,
            study_year: data.study_year,
            subject: data.subject,
          },
        }))
        return sendData
      },
    }),
  },
})

const $sendFinalTextForm = combine(
  $testType,
  $finalTextDropdownsPercent,
  $finalTextDropdownsPoints,
  (testType, finalTextDropdownsPercent, finalTextDropdownsPoints): FinalTextRelated[] => {
    if (testType === '0') {
      return finalTextDropdownsPercent.map((item) => {
        return { ...item, test_id: 0 }
      })
    }
    return finalTextDropdownsPoints.map((item) => {
      return { ...item, test_id: 0 }
    })
  }
)

// Отправка final-text
sample({
  source: {
    finalText: $sendFinalTextForm,
    oldId: $oldFinalText,
  },
  clock: updateTestFx.doneData.map((data) => data.body.id),
  fn: (data, id) => {
    return data.finalText.map((item) => ({
      id: item.id,
      isNew: item.isNew,
      score_min: item.score_min,
      score_max: item.score_max,
      text: item.text,
      test_id: id,
      oldId: data.oldId,
    }))
  },
  target: resendTestFinalTextArrFx,
})

// Отправка groups
sample({
  source: {
    groups: $groupsTests,
    oldId: $oldGroupId,
  },
  clock: updateTestFx.doneData.map((data) => data.body.id),
  fn: (data, id) => {
    return data.groups.map((group) => ({
      available_from: group.available_from,
      available_to: group.available_to,
      groupId: group.name,
      testId: id,
      oldId: data.oldId,
    }))
  },
  target: resendGroupsArrFx,
})

forward({
  from: clearFields,
  to: [
    typeDropdownModule.methods.resetDropdown,
    classesDropdownModule.methods.resetDropdown,
    subjectsDropdownModel.methods.resetDropdown,
    automaticGenerationFormClearFields,
    finalTextFormClearFieldsPercent,
    finalTextFormClearFieldsPoints,
    resetDateTime,
  ],
})

const $redirectHandler = sample({
  clock: updateTestFx.doneData.map((res) => res.body.id!),
  source: $redirectAfterSave,
  fn: (redirect, id) => ({ redirect, id }),
})

condition({
  source: $redirectHandler,
  if: (payload: { redirect: boolean; id: number }) => payload.redirect,
  then: navigatePush.prepend(() => ({ name: 'tests-list' })),
})

guard({
  source: toggleTestScope,
  filter: (testScope) => testScope === '0',
  target: resetGroupsTests,
})

const combinedEventsManual = combineEvents({
  events: {
    finalText: resendTestFinalTextArrFx.done,
    groups: resendGroupsArrFx.done,
    items: resendTestManualItemsArrFx.done,
  },
})

const combinedEventsAuto = combineEvents({
  events: {
    finalText: resendTestFinalTextArrFx.done,
    groups: resendGroupsArrFx.done,
    items: resendTestAutoItemsArrFx.done,
  },
})

sample({
  source: $testId,
  clock: [combinedEventsManual, combinedEventsAuto],
  target: [getTestFx, successToastEvent('Тест успешно обновлен!')],
})

const $formToGetThemeList = combine(
  $selectedSubject,
  $selectedClass,
  $filterByStudyYear,
  (subj, year, filterClass) => ({
    subject: subj && +subj.name,
    ...(filterClass ? { study_year: year && +year.name } : {}),
  })
)

const debounced = debounce({
  source: $formToGetThemeList,
  timeout: 150,
})

const $isFirstLoadTests = combine(
  $isPageLoaded,
  $loadingForEditThemes,
  $testId,
  (PageLoaded, loadingForEdit, id) => PageLoaded && !loadingForEdit && id !== 0
)

forward({
  from: debounced,
  to: condition({
    if: $isFirstLoadTests,
    then: getThemesTreeListFx,
  }),
})
