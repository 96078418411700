


































































































































import Vue from 'vue'
import BaseSwitch from '@/ui/switch/BaseSwitch.vue'
import BaseTextarea from '@/ui/input/BaseTextarea.vue'
import FormInput from '@/ui/input/FormInput.vue'
import LanguagesDropdown from '@/pages/bank/olympiad-tasks/create/parts/languages-dropdown/LanguagesDropdown.vue'
import ClassDropdown from '@/pages/common/dropdowns/class/ClassesDropdown.vue'
import GroupsFilterDropdown from '@/pages/common/dropdowns/multiselectDropdown/groups-dropdown-filter/GroupsFilterDropdown.vue'
import RadioGroup from '@/ui/radio/RadioGroup.vue'
import StartToEndDatetime from '@/pages/common/parts/tests/parts/datetime-picker/StartToEndDatetime.vue'
import SubjectDropdown from '@/pages/common/dropdowns/subject/SubjectsDropdown.vue'
import TypeDropdown from '@/pages/common/dropdowns/testing/type-dropdown/TypeDropdown.vue'
import Wysiwyg from '@/ui/wysiwyg/Wysiwyg.vue'
import {
  toggleArchive,
  $archive,
  $language,
  $testType,
  setTestType,
  $subject,
  setSubject,
  $wording,
  setWording,
  $containing,
  setContaining,
  toggleFilterByStudyYear,
  $filterByStudyYear,
  setCMSComment,
  $cmsComment,
  setStudyYear,
  $studyYear,
  toggleTestScope,
  $testScope,
  toggleTestAccess,
  $testAccess,
  toggleTimeLimit,
  $timeLimit,
  setMaxTime,
  $maxTime,
  $groupsTests,
  setAvailableTo,
  setAvailableFrom,
  setGroupsTests,
  setLanguage,
} from '@/pages/testing/tests/create/test-create-page.model'
import { $selectedThemes } from '@/pages/common/parts/tests/automatic-generation/parts/themes-dropdown/themes-dropdown.model'
import * as tests from '@/pages/common/parts/tests'
import { typeDropdownModule } from '@/pages/common/dropdowns/testing/type-dropdown/type-dropdown.model'
import { $tasksBlock } from '@/pages/common/parts/tests/manual-generation/manual-generation-form.model'
import TestsGroupsBlock from '@/pages/testing/tests/common/parts/groups-test-block/TestsGroupsBlock.vue'
import { DateTime } from '@/pages/common/parts/tests/parts/datetime-picker/types'
import { GroupTestsType } from '@/pages/testing/tests/types'

export default Vue.extend({
  name: 'ContentTestCreatePage',
  components: {
    BaseSwitch,
    BaseTextarea,
    ClassDropdown,
    GroupsFilterDropdown,
    RadioGroup,
    StartToEndDatetime,
    SubjectDropdown,
    TypeDropdown,
    Wysiwyg,
    FormInput,
    TestsGroupsBlock,
    LanguagesDropdown,
  },
  effector: {
    $archive,
    $language,
    $testType,
    $studyYear,
    $subject,
    $wording,
    $containing,
    $filterByStudyYear,
    $selectedThemes,
    $cmsComment,
    $testScope,
    $testAccess,
    $timeLimit,
    $maxTime,
    typeTestItem: typeDropdownModule.store.$item,
    $tasksBlock,
    $groupsTests,
  },
  computed: {
    testComponent() {
      if (this.$testType === null) {
        return null
      }
      return tests[this.$testType === '0' ? 'AutomaticGenerationForm' : 'ManualGenerationForm']
    },
    finalTextComponent() {
      if (this.$testType === null) {
        return null
      }
      return tests[this.$testType === '0' ? 'FinalTextFormPercent' : 'FinalTextFormPoints']
    },
    countLabel() {
      return `${
        this.typeTestItem && this.typeTestItem.name === '0'
          ? 'Количество тем в тесте'
          : 'Количество заданий в тесте'
      }: ${
        this.typeTestItem && this.typeTestItem.name === '0'
          ? this.$selectedThemes.length
          : this.$tasksBlock.length
      }`
    },
  },
  methods: {
    setStudyYear,
    setTestType,
    setSubject,
    setWording,
    setLanguage,
    setCMSComment,
    setContaining,
    toggleArchive,
    toggleFilterByStudyYear,
    toggleTestScope,
    toggleTestAccess,
    toggleTimeLimit,
    setMaxTime,
    setDateTime(data: DateTime) {
      setAvailableTo(data.end ? data.end.toDateString() : null)
      setAvailableFrom(data.start ? data.start.toDateString() : null)
    },
    addGroup(newGroup: GroupTestsType) {
      if (!this.$groupsTests.find((group) => group.id === newGroup.id))
        setGroupsTests([...this.$groupsTests, newGroup])
    },
    changeGroup(group: GroupTestsType) {
      setGroupsTests(this.$groupsTests.map((g) => (g.id === group.id ? group : g)))
    },
    removeGroup(id: number) {
      setGroupsTests(this.$groupsTests.filter((g) => g.id !== id))
    },
  },
  mounted() {
    setTestType('0')
    typeDropdownModule.methods.itemChanged({ name: '0', title: 'Автоматическая генерация' })
  },
})
