import { attach, combine, createEvent, forward, restore, sample } from 'effector'
import {
  $finalTextDropdownsPercent,
  checkFinalTextFormValidPercent,
  $finalTextFormValidPercent,
  clearFields as finalTextFormClearFieldsPercent,
} from '@/pages/common/parts/tests/parts/final-text-form-percent/final-text-form.model'
import {
  $finalTextDropdownsPoints,
  checkFinalTextFormValidPoints,
  $finalTextFormValidPoints,
  clearFields as finalTextFormClearFieldsPoints,
} from '@/pages/common/parts/tests/parts/final-text-form-points/final-text-points-form.model'
import {
  $isFilled as $isFilledAutomaticGenerationForm,
  clearAutomaticGenerationFields as automaticGenerationFormClearFields,
  clearAutomaticGenerationFields,
  $difficulty,
  $prerequisites,
} from '@/pages/common/parts/tests/automatic-generation/automatic-generation-form.model'
import { createTestFx } from '@/features/api/test/create-test'
import { CreateTestFxParams, FinalTextRelated } from '@/features/api/test/types'
import { successToastEvent } from '@/features/toasts/toasts.model'
import { createTestAutoItemsArrFx } from '@/features/api/test/create-test-auto-item'
import { createTestFinalTextArrFx } from '@/features/api/test/create-test-final-text'

import { typeDropdownModule } from '@/pages/common/dropdowns/testing/type-dropdown/type-dropdown.model'
import {
  $selectedClass,
  classesDropdownModule,
  setSelectedClass,
} from '@/pages/common/dropdowns/class/classes-dropdown.model'
import {
  $selectedSubject,
  subjectsDropdownModel,
} from '@/pages/common/dropdowns/subject/subjects-dropdown.model'
import { combineEvents, condition, debounce } from 'patronum'
import { DropdownItem } from '@/pages/common/types'
import { navigatePush } from '@/features/navigation/navigationMethods'
import {
  resetSelectedThemes,
  $selectedThemes,
  $isPageLoaded,
} from '@/pages/common/parts/tests/automatic-generation/parts/themes-dropdown/themes-dropdown.model'
import {
  $maxPoints,
  $tasksBlock,
  $taskScores,
  clearManualGenerationFields,
} from '@/pages/common/parts/tests/manual-generation/manual-generation-form.model'
import { guard, split } from 'effector-root'
import { createTestManualItemsArrFx } from '@/features/api/test/create-test-manual-item'
import { TaskBlock } from '@/pages/common/parts/tests/types'
import { GroupTestsType } from '@/pages/testing/tests/types'
import { resetDateTime } from '@/pages/common/parts/tests/parts/datetime-picker/start-to-end-datetime.model'
import { updateGroupsArrFx } from '@/features/api/test/create-test-groups'
import { getThemesTreeListFx } from '@/features/api/subject/get-themes-tree-list'
import { LANGUAGE_DATA } from '@/pages/bank/common/constants'

export const save = createEvent<void>()
export const clearFields = createEvent<void>()

export const resetGroupsTests = createEvent<void>()
export const setGroupsTests = createEvent<GroupTestsType[]>()
export const $groupsTests = restore(setGroupsTests, []).reset(clearFields, resetGroupsTests)

export const setRedirectAfterSave = createEvent<boolean>()
export const $redirectAfterSave = restore(setRedirectAfterSave, false).reset(clearFields)

export const setTestType = createEvent<string>()
export const $testType = restore(setTestType, '').reset(clearFields)

export const toggleFilterByStudyYear = createEvent<boolean>()
export const $filterByStudyYear = restore(toggleFilterByStudyYear, true).reset(clearFields)

export const toggleArchive = createEvent<boolean>()
export const $archive = restore(toggleArchive, false).reset(clearFields)

export const setLanguage = createEvent<DropdownItem>()
export const $language = restore(setLanguage, LANGUAGE_DATA[0]).reset(clearFields)

export const setStudyYear = createEvent<string>()
export const $studyYear = restore(setStudyYear, '').reset(clearFields)

export const setSubject = createEvent<string>()
export const $subject = restore(setSubject, '').reset(clearFields)

export const setWording = createEvent<string>()
export const $wording = restore(setWording, '').reset(clearFields)

export const setContaining = createEvent<string>()
export const $containing = restore(setContaining, '').reset(clearFields)

export const toggleTestScope = createEvent<string>()
export const $testScope = restore(toggleTestScope, '1').reset(clearFields)

export const setCMSComment = createEvent<string>()
export const $cmsComment = restore(setCMSComment, '').reset(clearFields)

export const toggleTestAccess = createEvent<boolean>()
export const $testAccess = restore(toggleTestAccess, true).reset(clearFields)

export const setAvailableFrom = createEvent<string | null>()
export const $availableFrom = restore(setAvailableFrom, null).reset(clearFields)

export const setAvailableTo = createEvent<string | null>()
export const $availableTo = restore(setAvailableTo, null).reset(clearFields)

export const toggleTimeLimit = createEvent<boolean>()
export const $timeLimit = restore(toggleTimeLimit, false).reset(clearFields)

export const setMaxTime = createEvent<number>()
export const resetMaxTime = createEvent<number>()
export const $maxTime = restore(setMaxTime, null).reset(clearFields, resetMaxTime)

export const $isLoadingCreateTests = combine(
  createTestFx.pending,
  createTestAutoItemsArrFx.pending,
  createTestFinalTextArrFx.pending,
  createTestManualItemsArrFx.pending,
  updateGroupsArrFx.pending,
  (test, AutoItems, FinalTexts, groups) => test || AutoItems || FinalTexts || groups
)

forward({
  from: toggleFilterByStudyYear,
  to: [
    setStudyYear.prepend(() => ''),
    setSelectedClass.prepend(() => null),
    classesDropdownModule.methods.resetDropdown,
  ],
})

const $settingsFormFields = combine(
  $archive,
  $maxTime,
  $cmsComment,
  $prerequisites,
  $taskScores,
  $filterByStudyYear,
  $availableFrom,
  $availableTo,
  $language,
  $testScope,
  (
    archive,
    maxTime,
    cmsComment,
    prerequisites,
    taskScores,
    filterByStudyYear,
    availableFrom,
    availableTo,
    language,
    testScope
  ) => {
    return {
      status: archive ? 0 : 1,
      availability: Number(testScope),
      duration_min: maxTime,
      cms_comment: cmsComment,
      use_prerequisites: prerequisites,
      use_assignment_score: taskScores,
      use_only_class_assignments: filterByStudyYear,
      available_from: availableFrom ? new Date(availableFrom) : null,
      available_to: availableTo ? new Date(availableTo) : null,
      interface_language: language.name.slice(0, 2),
    }
  }
)

const $baseForm = combine(
  $subject,
  $difficulty,
  $testType,
  $filterByStudyYear,
  $wording,
  $containing,
  $studyYear,
  $settingsFormFields,
  (
    selectedSubject,
    difficulty,
    testType,
    filterByStudyYear,
    wording,
    containing,
    selectedClass,
    settingsFormFields
  ): CreateTestFxParams => ({
    name: wording,
    instruction: containing,
    generator: Number(testType),
    ...(testType === '0' && { difficulty }),
    filter_by_year: filterByStudyYear,
    study_year_id: selectedClass ? +selectedClass : null,
    subject_id: +selectedSubject!,
    ...settingsFormFields,
  })
)

const getRequiredText = (textArr: FinalTextRelated[]) => {
  return textArr.filter((item) => item.text && item.text.length > 0)
}

export const getValidRange = (textArr: FinalTextRelated[], maxValue: number) => {
  let valid = true
  const arr: Array<number | null> = []
  textArr.forEach((item) => {
    if (item.score_min !== null && item.score_max !== null) {
      for (let i = item.score_min; i <= item.score_max; i++) {
        if (arr.includes(i)) {
          valid = false
          break
        }
        arr.push(i)
      }
    }
  })
  if (
    arr.includes(null) ||
    !arr.includes(0) ||
    !arr.includes(maxValue) ||
    arr.length !== maxValue + 1
  ) {
    valid = false
  }
  return valid
}

export const $validFinalTextPercentArr = $finalTextDropdownsPercent.map((textArr) =>
  getRequiredText(textArr)
)

// валидация финал текста по процентам
export const $validRangeFinalTextPercent = $finalTextDropdownsPercent.map((textArr) =>
  getValidRange(textArr, 100)
)

export const $validFinalTextPointsArr = $finalTextDropdownsPoints.map((textArr) =>
  getRequiredText(textArr)
)

// валидация финал текста по очкам
export const $validRangeFinalTextPoints = combine(
  $finalTextDropdownsPoints,
  $maxPoints,
  (fields, points) => getValidRange(fields, points)
)

// валидация финал текста
export const $canSaveValidFinalText = combine(
  $testType,
  $validFinalTextPercentArr,
  $validRangeFinalTextPercent,
  $finalTextDropdownsPercent,
  $validFinalTextPointsArr,
  $validRangeFinalTextPoints,
  $finalTextDropdownsPoints,
  (
    testType,
    validFinalTextPercentArr,
    validRangeFinalTextPercent,
    finalTextPercent,
    validFinalTextPointsArr,
    validRangeFinalTextPoints,
    finalTextPoints
  ) => {
    const isValidFinalTextPercent =
      validFinalTextPercentArr.length === finalTextPercent.length && validRangeFinalTextPercent
    const isValidFinalTextPoints =
      validFinalTextPointsArr.length === finalTextPoints.length && validRangeFinalTextPoints
    return testType === '0' ? isValidFinalTextPercent : isValidFinalTextPoints
  }
)

const $canSaveGroups = combine($testScope, $groupsTests, (testScope, groups) => {
  if (testScope === '1') return true
  return testScope === '0' && groups.length > 0
  // return groups.filter((group) => !group.available_to || !group.available_from).length === 0
})

const $canSaveAutoGeneration = combine(
  $testType,
  $isFilledAutomaticGenerationForm,
  $selectedThemes,
  (testType, isFilled, selectedThemes) => {
    return (
      (testType === '0' ? isFilled : true) && (testType === '0' ? selectedThemes.length > 1 : true)
    )
  }
)

export const $canSave = combine(
  $subject,
  $wording,
  $containing,
  $studyYear,
  $canSaveValidFinalText,
  $filterByStudyYear,
  $canSaveGroups,
  $testScope,
  $canSaveAutoGeneration,
  (
    subject,
    wording,
    containing,
    studyYear,
    finalText,
    filterByStudyYear,
    canSaveGroups,
    testScope,
    canSaveAutoGeneration
  ) => {
    return (
      subject &&
      wording &&
      containing &&
      finalText &&
      (filterByStudyYear ? !!studyYear : true) &&
      canSaveGroups &&
      !!studyYear &&
      canSaveAutoGeneration
    )
  }
)

forward({
  from: setTestType,
  to: [resetSelectedThemes, clearManualGenerationFields, clearAutomaticGenerationFields],
})

sample({
  source: $finalTextFormValidPercent,
  clock: checkFinalTextFormValidPercent,
  target: condition({
    if: (res: boolean) => res,
    then: save,
  }),
})

sample({
  source: $finalTextFormValidPoints,
  clock: checkFinalTextFormValidPoints,
  target: condition({
    if: (res: boolean) => res,
    then: save,
  }),
})

sample({
  source: $baseForm,
  clock: save,
  target: createTestFx,
})

// Отправка auto-item или manual-item в зависимости от типа
split({
  source: createTestFx.doneData.map((data) => data.body.id),
  match: $testType,
  cases: {
    '1': attach({
      effect: createTestManualItemsArrFx,
      source: $tasksBlock,
      mapParams: (id: number, data) => {
        const sendData = data.map((item: TaskBlock) => ({
          score: item.pointsForTask,
          order_number: id,
          test_id: id,
          assignments_ids: item.tasks.map((task) => Number(task.name)),
        }))
        return sendData
      },
    }),
    '0': attach({
      effect: createTestAutoItemsArrFx,
      source: { themes: $selectedThemes, subject: $subject, study_year: $studyYear },
      mapParams: (id: number, data) => {
        const sendData = data.themes.map((item: DropdownItem) => ({
          test_id: id,
          order_number: id,
          theme_id: Number(item.name),
          theme: {
            name: item.name,
            study_year: data.study_year,
            subject: data.subject,
          },
        }))
        return sendData
      },
    }),
  },
})

const $sendFinalTextForm = combine(
  $testType,
  $finalTextDropdownsPercent,
  $finalTextDropdownsPoints,
  (testType, finalTextDropdownsPercent, finalTextDropdownsPoints): FinalTextRelated[] => {
    if (testType === '0') {
      return finalTextDropdownsPercent.map((item) => {
        return { ...item, test_id: 0 }
      })
    }
    return finalTextDropdownsPoints.map((item) => {
      return { ...item, test_id: 0 }
    })
  }
)
// Отправка final-text
sample({
  source: $sendFinalTextForm,
  clock: createTestFx.doneData.map((data) => data.body.id),
  fn: (arr, id) => {
    return arr.map((item) => ({ ...item, test_id: id }))
  },
  target: createTestFinalTextArrFx,
})

// Отправка groups
sample({
  source: $groupsTests,
  clock: createTestFx.doneData.map((data) => data.body.id),
  fn: (arr, id) => {
    return arr.map((group) => ({
      available_from: group.available_from,
      available_to: group.available_to,
      groupId: group.name,
      testId: id,
      oldId: [],
    }))
  },
  target: updateGroupsArrFx,
})

forward({
  from: clearFields,
  to: [
    typeDropdownModule.methods.resetDropdown,
    classesDropdownModule.methods.resetDropdown,
    subjectsDropdownModel.methods.resetDropdown,
    automaticGenerationFormClearFields,
    finalTextFormClearFieldsPercent,
    finalTextFormClearFieldsPoints,
    resetDateTime,
  ],
})

const $redirectHandler = sample({
  clock: createTestFx.doneData.map((res) => res.body.id!),
  source: $redirectAfterSave,
  fn: (redirect, id) => ({ redirect, id }),
})

condition({
  source: $redirectHandler,
  if: (payload: { redirect: boolean; id: number }) => payload.redirect,
  then: navigatePush.prepend(() => {
    return { name: 'tests-list' }
  }),
  else: navigatePush.prepend((payload: { redirect: boolean; id: number }) => ({
    name: 'test-edit-page',
    params: { id: `${payload.id}` },
  })),
})

guard({
  source: $testScope,
  filter: (testScope) => testScope === '1',
  target: resetGroupsTests,
})

const combinedEventsManual = combineEvents({
  events: {
    finalText: createTestFinalTextArrFx.done,
    groups: updateGroupsArrFx.done,
    items: createTestManualItemsArrFx.done,
  },
})

const combinedEventsAuto = combineEvents({
  events: {
    finalText: createTestFinalTextArrFx.done,
    groups: updateGroupsArrFx.done,
    items: createTestAutoItemsArrFx.done,
  },
})

sample({
  clock: [combinedEventsManual, combinedEventsAuto],
  target: [successToastEvent('Тест успешно создан!'), clearFields],
})

const $formToGetThemeList = combine(
  $selectedSubject,
  $selectedClass,
  $filterByStudyYear,
  (subj, year, filterClass) => ({
    subject: subj && +subj.name,
    ...(filterClass ? { study_year: year && +year.name } : {}),
  })
)

const debounced = debounce({
  source: $formToGetThemeList,
  timeout: 150,
})

forward({
  from: debounced,
  to: condition({
    if: $isPageLoaded,
    then: getThemesTreeListFx,
  }),
})
