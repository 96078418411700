import { createEvent, createStore, guard, restore, combine, sample, forward } from 'effector-root'
import { navigatePush } from '@/features/navigation/navigationMethods'
import { getStudentByIdFx } from '@/features/api/student/get-student-by-id'
import { resetFields, studentForm } from '@/pages/users/students/edit/model/form-fields.model'
import { updateStudentByIdFx } from '@/features/api/student/update-student-by-id'
import { successToastEvent, errorToastEvent } from '@/features/toasts/toasts.model'
import { getStudentTokenFx } from '@/features/api/employee/getStudentToken'
import { config } from '@/config'

export const getPageParams = createEvent()

export const saveStudentInfo = createEvent()

export const getStudentToken = createEvent()

export const setRedirectAfterSave = createEvent<boolean>()
export const $redirectAfterSave = restore(setRedirectAfterSave, false).reset([
  navigatePush,
  updateStudentByIdFx.fail,
])

export const changeStudentId = createEvent<string | never>()

export const $editedStudentId = createStore<string>('')
  .on(changeStudentId, (state, payload) => payload || state)
  .reset(resetFields)

guard({
  source: changeStudentId,
  filter: (payload) => !!payload,
  target: getStudentByIdFx,
})

const formById = combine($editedStudentId, studentForm, (id, student) => ({
  id,
  body: student,
}))

sample({
  source: formById,
  clock: saveStudentInfo,
  target: updateStudentByIdFx,
})

guard({
  source: updateStudentByIdFx.done,
  filter: $redirectAfterSave,
  target: navigatePush.prepend(() => ({ name: 'users-list' })),
})

forward({
  from: updateStudentByIdFx.done,
  to: successToastEvent('Студент успешно сохранен'),
})

sample({
  source: $editedStudentId,
  clock: getStudentToken,
  target: getStudentTokenFx,
})

forward({
  from: getStudentTokenFx.failData,
  to: errorToastEvent('Не удалось получить данные'),
})

getStudentTokenFx.doneData.watch((res) => {
  window.open(`${config.INTERFACE_DOMAIN}/from-cms?role=admin&token=${res.body.access}`)
})
