const dataSource = process.env.BUILD_TARGET === 'client' ? window.env : process.env

export const config = {
  BACKEND_URL: dataSource.BACKEND_URL,
  TOKEN_KEY: 'token',
  TOKEN_EXP: 'token_exp',
  REFRESH_TOKEN_KEY: 'refresh_token',
  REFRESH_TOKEN_EXP: 'refresh_token_exp',
  PREVIEW_URL: dataSource.PREVIEW_URL,
  USER_KEY: 'user',
  INTERFACE_DOMAIN: dataSource.INTERFACE_DOMAIN,
  COMMIT_MESSAGE: dataSource.COMMIT_MESSAGE,
  COMMIT_INITIATOR: dataSource.COMMIT_INITIATOR,
  COMMIT_DATE: dataSource.COMMIT_DATE,
}
