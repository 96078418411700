














































import Vue, { PropType } from 'vue'
import Icon from '@/ui/icon/Icon.vue'
import BaseButton from '@/ui/button/BaseButton.vue'
import { $editedLessonId } from '@/pages/learning/learning-lessons/create/model/edit-lesson.model'
import { navigatePush } from '@/features/navigation/navigationMethods'
import { $token } from '@/features/api/common/request'
import { config } from '@/config'

export default Vue.extend({
  name: 'CreateLessonHeader',
  components: {
    Icon,
    BaseButton,
  },
  props: {
    title: { type: String as PropType<string>, default: '' },
    disabled: { type: Boolean as PropType<boolean>, default: true },
    isPreview: { type: Boolean as PropType<boolean> },
    fromPage: { type: String as PropType<string> },
    taskType: { type: String as PropType<string> },
    statusController: { type: Boolean as PropType<boolean>, default: false },
  },
  effector: {
    lessonId: $editedLessonId,
    token: $token,
  },
  methods: {
    goToList() {
      switch (this.$route.name) {
        default:
          navigatePush({ name: 'lessons-list' })
      }
    },
    onSaveClick() {
      this.$emit('save')
    },
    openLesson() {
      window.open(`${config.INTERFACE_DOMAIN}/lesson/${this.lessonId}/preview?token=${this.token}`)
    },
    onSaveAndBackClick() {
      this.$emit('saveAndBackToList')
    },
  },
})
