import { StudyYear, Subject } from '@/features/api/subject/types'
import { DropdownItem } from '@/pages/common/types'
import { TableField } from '@/pages/dictionary/themes/list/types'
import dayjs from 'dayjs'
import { ActionsDisplayConditions } from '@/pages/common'
import { EmployeePermissions } from '@/features/api/employee/types'

export const testsDataFields: TableField[] = [
  {
    name: '__checkbox',
    title: '',
    titleClass: 'center aligned',
    dataClass: 'center aligned',
    width: '50px',
  },
  {
    name: 'id',
    sortField: 'id',
    title: 'ID теста',
    width: '130px',
  },
  {
    name: 'name',
    sortField: 'name',
    title: 'Название',
    dataClass: 'wrap',
    width: '150px',
  },
  {
    name: 'subject',
    sortField: 'subject',
    title: 'Предмет',
    width: '140px',
    formatter(subject: Subject) {
      return subject && subject.name ? subject.name : '-'
    },
  },
  {
    name: 'study_year',
    sortField: 'study_year',
    titleClass: 'center aligned',
    dataClass: 'center aligned',
    title: 'Класс',
    width: '100px',
    formatter(study_year: StudyYear) {
      return study_year && study_year.name ? study_year.name.split(' ')[0] : '-'
    },
  },
  {
    name: 'difficulty',
    sortField: 'difficulty',
    dataClass: 'wrap',
    title: 'Сложность',
    width: '120px',
    formatter(difficulty: number) {
      return difficulty ? 'Тест продвинутый' : 'Тест базовый'
    },
  },
  {
    name: 'themes_amount',
    sortField: 'themes_amount',
    title: 'Кол-во тем',
    dataClass: 'center aligned',
    width: '100px',
    formatter(count: number) {
      return count || '-'
    },
  },
  {
    name: 'assignments_amount',
    sortField: 'assignments_amount',
    title: 'Кол-во заданий',
    dataClass: 'center aligned',
    width: '150px',
    formatter(count: number) {
      return count || '-'
    },
  },
  {
    name: 'generator',
    sortField: 'generator',
    title: 'Тип ген.',
    width: '80px',
  },
  {
    name: 'filter_by_year',
    sortField: 'filter_by_year',
    title: 'Фильтр. заданий по классу',
    dataClass: 'center aligned',
    width: '150px',
    formatter(filter_by_year: boolean) {
      return filter_by_year ? 'да' : 'нет'
    },
  },
  {
    name: 'duration_min',
    sortField: 'duration_min',
    title: 'Длительность теста',
    dataClass: 'center aligned',
    width: '150px',
    formatter(duration_min: number) {
      return duration_min || '-'
    },
  },
  {
    name: 'groups',
    sortField: 'groups_names',
    title: 'Группы',
    dataClass: 'wrap',
    width: '150px',
  },
  {
    name: 'status',
    sortField: 'status',
    title: 'Статус',
    width: '140px',
    formatter(status: number) {
      return status ? 'Опубликовано' : 'Архив'
    },
  },
  {
    name: 'created_at',
    sortField: 'created_at',
    title: 'Создано',
    width: '130px',
    formatter(datetime: string) {
      return datetime ? dayjs(datetime).format('DD.MM.YYYY') : '-'
    },
  },
  {
    name: 'updated_at',
    sortField: 'updated_at',
    title: 'Обновлено',
    width: '130px',
    formatter(datetime: string) {
      return datetime ? dayjs(datetime).format('DD.MM.YYYY') : '-'
    },
  },
  {
    name: 'created_by',
    sortField: 'created_by',
    title: 'Создатель',
    width: '150px',
  },
  {
    name: 'updated_by',
    sortField: 'updated_by',
    title: 'Посл. изменение',
    width: '200px',
  },
  {
    name: 'cms_comment',
    sortField: 'cms_comment',
    title: 'Комментарий',
    width: '150px',
  },
  {
    name: 'actions',
    title: '',
    dataClass: 'actions-cell right aligned',
    width: '120px',
  },
]

export const searchFieldsData: DropdownItem[] = [
  { name: 'all', title: 'Искать везде' },
  { name: 'id', title: 'ID теста' },
  { name: 'name', title: 'Название' },
  { name: 'study_year', title: 'Класс' },
  { name: 'subject', title: 'Предмет' },
  { name: 'groups_names', title: 'Группа' },
]

export const getTestsActions = (
  displayOptions: ActionsDisplayConditions,
  permissions: EmployeePermissions
) => {
  const actions = [
    {
      name: 'edit',
      title: 'Редактировать',
      action: 'onEdit',
      isDisplayed: displayOptions.isTableItem || displayOptions.isHeaderItem,
      hasPermissionToSee: permissions.tests_test_tests.can_edit,
    },
    {
      name: 'delete',
      title: 'Удалить',
      action: 'onDelete',
      isDisplayed:
        displayOptions.isTableItem ||
        displayOptions.isTableItems ||
        displayOptions.isHeaderItem ||
        displayOptions.isHeaderItems,
      hasPermissionToSee: true,
    },
    {
      name: 'removeSelection',
      title: 'Снять выделение',
      action: 'onRemoveSelection',
      isDisplayed: displayOptions.isHeaderItems,
      hasPermissionToSee: true,
    },
  ]

  return actions.filter((action) => action.isDisplayed && action.hasPermissionToSee)
}
